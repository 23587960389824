import { Component, OnInit, ComponentFactoryResolver, Input, ViewChild, Type, AfterViewInit, Directive  } from '@angular/core';
import { QuoteDirective } from '../../directives/quote.directive';
import { CarRentalComponent } from '../car-rental/car-rental.component';
import { TravelNonAgeRatedComponent } from '../travel-non-age-rated/travel-non-age-rated.component';
import { TravelHotelComponent } from '../travel-hotel/travel-hotel.component';
import { TravelHotelSamedayComponent } from '../travel-hotel-sameday/travel-hotel-sameday.component';
import { TravelAgeRatedComponent } from '../travel-age-rated/travel-age-rated.component';
import { RailComponent } from '../rail/rail.component';
import { QuoteComponentEnum } from '../../models/quote-component.enum';
import { DestinationEnum } from '../../models/destination.enum';
import { EventComponent } from '../event/event.component';
import { EventRegistrationComponent } from '../event-registration/event-registration.component';
import { AnnualComponent } from '../annual/annual.component';
import { SessionSeasonComponent } from '../session-season/session-season.component';


@Component({
    selector: 'app-quote',
    templateUrl: './quote.component.html',
    styleUrls: ['./quote.component.scss'],
    imports: [QuoteDirective]
})
export class QuoteComponent implements OnInit, AfterViewInit {

  @ViewChild(QuoteDirective, { static: true }) quoteHost: QuoteDirective;

  componentRef;

  @Input() quoteWidget: QuoteComponentEnum;
  @Input() destinationSelection: string;
  @Input() quoteButtonText = '';
  @Input() quoteButtonColor = '';
  @Input() quoteButtonHoverColor = '';
  @Input() showButton = true;
  @Input() componentId = '';

  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.resolveChildComponent(this.quoteWidget);
  }

  ngAfterViewInit() { }

  loadComponent(component: Type<any>) {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);

    const viewContainerRef = this.quoteHost.viewContainerRef;
    viewContainerRef.clear();

    const componentRef = viewContainerRef.createComponent(componentFactory);
    this.componentRef = componentRef;

    componentRef.instance.destinationEnum = <DestinationEnum>this.destinationSelection;
    componentRef.instance.showButton = this.showButton;
    componentRef.instance.quoteButtonText = this.quoteButtonText;
    componentRef.instance.quoteButtonColor = this.quoteButtonColor;
    componentRef.instance.quoteButtonHoverColor = this.quoteButtonHoverColor;
    componentRef.instance.componentId = this.componentId;
  }

  private resolveChildComponent(next: QuoteComponentEnum) {
    switch (next) {
      case QuoteComponentEnum.CarRental:
        this.loadComponent(CarRentalComponent);
        break;
      case QuoteComponentEnum.TravelAgeRated:
        this.loadComponent(TravelAgeRatedComponent);
        break;
      case QuoteComponentEnum.Rail:
        this.loadComponent(RailComponent);
        break;
      case QuoteComponentEnum.TravelNonAgeRated:
        this.loadComponent(TravelNonAgeRatedComponent);
        break;
      case QuoteComponentEnum.EventTicket:
        this.loadComponent(EventComponent);
        break;
      case QuoteComponentEnum.EventRegistration:
        this.loadComponent(EventRegistrationComponent);
        break;
      case QuoteComponentEnum.Annual:
        this.loadComponent(AnnualComponent);
        break;
      case QuoteComponentEnum.SessionSeason:
        this.loadComponent(SessionSeasonComponent);
        break;
      case QuoteComponentEnum.TravelHotel:
        this.loadComponent(TravelHotelComponent);
        break;
      case QuoteComponentEnum.TravelHotelSameday:
        this.loadComponent(TravelHotelSamedayComponent);
        break;
    }
  }

}
