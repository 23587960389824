import { Component, OnInit, Directive } from '@angular/core';
import { DomainTypeEnum } from '../../policy/models/domain-type.enum';
import { AppStateService } from '../../shared/services/app-state.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';


@Component({
    selector: 'app-privacy',
    templateUrl: './privacy.component.html',
    styleUrls: ['./privacy.component.scss'],
    standalone: true
})
export class PrivacyComponent implements OnInit {
  constructor(private appStateService: AppStateService,
    private router: Router) { }

  ngOnInit() {

    //this workaround is to refresh the page because of the dynamically
   //script added to the page. If this script tag is removed, the js file
   //will be still in the browser's cache and once you hit the privacy page
   //this addition script will fail.
   //so, once the page is refreshed, the script will be gone and can be re-added again
    if (localStorage.getItem('refreshed') === null) {
      localStorage['refreshed'] = true;
      window.location.reload();
    } else {
        localStorage.removeItem('refreshed');
    }

    var partnerConfig = this.appStateService.getPartnerConfig();

    if (partnerConfig) {

      var domainTag = this.returnDomainTagURL(partnerConfig.domain);

      // Pulled from Shared Contents
      const node = document.createElement('script');
      node.id = 'privacy';
      node.src = domainTag;
      node.async = true;
      document.getElementsByTagName('head')[0].appendChild(node);
    }

  }

  private returnDomainTagURL(domain) {
    switch (domain) {
      case(DomainTypeEnum.Travel):
        return `${environment.eTravelDomainTagURL}`;
      case(DomainTypeEnum.EventTicketProtection):
        return `${environment.eventTicketProtectionTagURL}`;
      case(DomainTypeEnum.EventRegistration):
        return `${environment.eventRegistrationProtectionTagURL}`;
      default: return `${environment.defaultDomainTagURL}`;
    }
  }

  //remove the script above when leaving the privacy page
  deleteScriptWhenLeaveRoute(): boolean {
    const element = document.getElementById('privacy');
    if(element){
      element.parentElement.removeChild(element);
      return true;
    }
    return false;
  }

}
