import { ToolTipConstants } from './../../../shared/constants/tool-tip-constants';
import { Component, OnInit, Input, ViewChild, OnDestroy, Directive  } from '@angular/core';
import { UtilService } from '../../../shared/services/util.service';
import { SelectListItem } from '../../../shared/models';
import { UntypedFormGroup, FormControl, UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';
import { DestinationEnum } from '../../models/destination.enum';
import { Observable ,  Subscription ,  Subject } from 'rxjs';


import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ESitesNgbDateParserFormatter } from '../../services/date-parser-formatter';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap/datepicker/ngb-date-struct';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { restrictedTypeaheadValueChangesCallback } from '../../../shared/validators/validate-restricted-typeahead';
import { NgbTypeahead } from '@ng-bootstrap/ng-bootstrap/typeahead/typeahead';
import { Store, Action } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import {
  GetAnnualTravelQuote,
  GenerateQuoteActionTypes
} from '../../actions/generate-quote';
import { QuoteActionTypes } from '../../actions/quote';
import { Actions, ofType } from '@ngrx/effects';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { ButtonEnum } from '../../models/button.enum';
import { map } from 'rxjs/operators';
import { AnnualWidgetFormData } from '../../models/form-data.interface';
import { FormValidationComponent } from '../../../shared/components/form-validation/form-validation.component';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputValidationComponent } from '../../../shared/components/input-validation/input-validation.component';
import { NgFor, NgSwitch, NgSwitchCase, AsyncPipe } from '@angular/common';



@Component({
    selector: 'app-annual',
    templateUrl: './annual.component.html',
    styleUrls: ['./annual.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
        ESitesNgbDateParserFormatter
    ],
    imports: [ReactiveFormsModule, NgFor, InputValidationComponent, NgSwitch, NgSwitchCase, NgSelectModule, DynamicButtonComponent, FormValidationComponent, AsyncPipe]
})
export class AnnualComponent implements OnInit, OnDestroy {

  MAX_NUM_TRAVELERS = 9;
  annualForm: UntypedFormGroup;
  states: SelectListItem[];
  errors: String[] = [];
  buttonDisabled = false;
  partnerName: string;

  destEnum = DestinationEnum;
  destSub: Subscription;
  destTerm: string;
  destinations: Observable<Array<SelectListItem>>;

  @Input() destinationEnum;
  @Input() quoteButtonText;
  @Input() quoteButtonColor;
  @Input() quoteButtonHoverColor;
  @Input() componentId: string;

  private destSelection = new Subject<String>();
  destSelectionObservable$: Observable<
    String
  > = this.destSelection.asObservable();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.annualForm = this.formBuilder.group({
      destination: '',
      state: ['', Validators.required],
      numTravelers: [1, Validators.required]
    });
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.destinations = this.utilService.getDestinations();

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as AnnualWidgetFormData;

    if (typeof sessionQuoteForm !== 'undefined' && sessionQuoteForm !== null) {
      this.annualForm.patchValue({
        state: sessionQuoteForm.destination,
        numTravelers: sessionQuoteForm.numTravelers,
        destination: sessionQuoteForm.destination
      });
    }
  }

  ngOnDestroy() {
    if (this.destSub) {
      this.destSub.unsubscribe();
    }
  }

  get numTravelersArray(): number[] {
    const arr = [];
    let i = 1;
    while (arr.push(i++) < this.MAX_NUM_TRAVELERS) {}
    return arr;
  }

  onSubmit() {
    this.errors = [];
    if (this.annualForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData : AnnualWidgetFormData = {
        state: this.state.value,
        destination: this.destination.value,
        numTravelers: this.numTravelers.value as number
      }
      this.store.dispatch(
        new GetAnnualTravelQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetAnnualQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
          this.buttonDisabled = false;
          this.ga.formSubmit(ButtonEnum.GetQuote);
          this.appStateService.clearManagePolicyState();
          this.appStateService.clearPurchaseState();
          this.appStateService.setPurchaseSessionActive(true);
          this.appStateService.setQuoteForm(quoteFormData);
          this.appStateService.setQuote(q);
          this.appStateService.broadcastQuote(q);
          this.router.navigateByUrl(`${this.partnerName}/policy/products`);
        });

        this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetAnnualQuoteFailure),
          map(action => action.payload)
        ).subscribe((err: HttpErrorResponse) => {
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });


    } else {
      this.utilService.validateAllFormFields(this.annualForm);
    }
  }

  destSelected($event) {
    const value = $event ? $event.desc : '';
    this.destSelection.next(value);
  }

  get destination() {
    return this.annualForm.get('destination');
  }

  get state() {
    return this.annualForm.get('state');
  }
  get numTravelers() {
    return this.annualForm.get('numTravelers');
  }
}
