import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { ESitesNgbDateParserFormatter } from './services/date-parser-formatter';
import { QuoteService } from './services/quote.service';
import { CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { MatNativeDateModule } from '@angular/material/core';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WidgetComponent } from './widget.component';
import { RailComponent } from './components/rail/rail.component';
import { CarRentalComponent } from './components/car-rental/car-rental.component';
import { TravelNonAgeRatedComponent } from './components/travel-non-age-rated/travel-non-age-rated.component';
import { TravelHotelComponent } from './components/travel-hotel/travel-hotel.component';
import { TravelHotelSamedayComponent } from './components/travel-hotel-sameday/travel-hotel-sameday.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { QuoteDirective } from './directives/quote.directive';
import { SharedModule } from '../shared/shared.module';
import { TravelAgeRatedComponent } from './components/travel-age-rated/travel-age-rated.component';
import { EventComponent } from './components/event/event.component';
import { QuoteComponent } from './components/quote/quote.component';
import { EventRegistrationComponent } from './components/event-registration/event-registration.component';
import { AnnualComponent } from './components/annual/annual.component';
import { SessionSeasonComponent } from './components/session-season/session-season.component';

@NgModule({
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgbModule,
        SharedModule,
        WidgetComponent,
        RailComponent,
        CarRentalComponent,
        TravelNonAgeRatedComponent,
        QuoteDirective,
        TravelAgeRatedComponent,
        EventComponent,
        QuoteComponent,
        EventRegistrationComponent,
        AnnualComponent,
        SessionSeasonComponent,
        TravelHotelComponent,
        TravelHotelSamedayComponent
    ],
    exports: [
        WidgetComponent,
        QuoteComponent
    ]
})
export class QuoteModule {
  static forRoot(): ModuleWithProviders<QuoteModule> {
    return {
      ngModule: QuoteModule,
      providers: [QuoteService]
    };
  }
}
