import { Component, OnInit, Inject, Directive  } from '@angular/core';
import { DOCUMENT } from "@angular/common";


@Component({
    selector: 'app-purchase-agreement',
    templateUrl: './purchase-agreement.component.html',
    styleUrls: ['./purchase-agreement.component.scss'],
    standalone: true
})
export class PurchaseAgreementComponent implements OnInit {

  constructor(@Inject(DOCUMENT) private document) {
  }

  ngOnInit() {
    // Pulled from Shared Contents
    const node = document.createElement('script');
    node.id = 'purchase';
    node.src = 'https://content.allianzpartnerservices.com/remote-content/v2/purchase-agreement.js?domain=esites&target=_blank';
    node.async = true;
    document.getElementsByTagName('head')[0].appendChild(node);
  }

}
