import { ErrorMessage } from '../../shared/models';
import { AdditionalInformation, BookingAgentInformation, FlightItineraryInformation,
         GDSInformation, HotelInformation, OrderInformation, TripInformation, TravelInformation,
         RentalCarInformation, QuoteProductInformation, SecondChanceInformation, MarketingInformation } from '.';

export interface Booking {
    errorMessages: ErrorMessage[];
    message: string;
    transactionNumber: string;
    transactionStatus: string;
    quoteCode: string;
    bookingID: number;
    isQuoteValid: boolean;
    bookingGAS: number;
    policyArchiveLocation: string;
    analyticsInfo: string;
    hasValidationErrors: boolean;
    bookingSource: number;
    additionalInformation: AdditionalInformation;
    bookingAgentInformation: BookingAgentInformation;
    flightItineraryInformation: FlightItineraryInformation;
    gdsInformation: GDSInformation;
    hotelInformation: HotelInformation;
    orderInformation: OrderInformation;
    tripInformation: TripInformation;
    travelInformation: TravelInformation;
    rentalCarInformation: RentalCarInformation;
    productInformation: QuoteProductInformation;
    secondChanceInformation: SecondChanceInformation;
    marketingInformation: MarketingInformation;
    coSource: string;
    source: string;
    domainType: string;
}
