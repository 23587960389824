import { Component, OnInit, Input, Directive  } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-dynamic-button',
    templateUrl: './dynamic-button.component.html',
    styleUrls: ['./dynamic-button.component.scss'],
    imports: [MatButtonModule]
})
export class DynamicButtonComponent implements OnInit {

  @Input() buttonText: string;

  @Input() buttonBackgroundColor: string;

  @Input() buttonHoverColor: string;

  @Input() disabled: boolean;

  @Input() compId: string;

  hovering: boolean;

  constructor() { }

  ngOnInit() {
  }

}
