import { SimpleChanges } from '@angular/core';

import { Subscription } from 'rxjs';
import { AppStateService } from '../../services/app-state.service';
import { Component, OnInit, ViewEncapsulation, Input, OnDestroy, OnChanges, Directive  } from '@angular/core';
import { PhonePipe } from '../../pipes/phone.pipe';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-telephone',
    templateUrl: './telephone.component.html',
    styleUrls: ['./telephone.component.scss'],
    imports: [MatButtonModule]
})
export class TelephoneComponent implements OnInit, OnDestroy, OnChanges {

  @Input() phoneNumber: string;

  configSub: Subscription;
  get displayText(): string { return this.phonePipe.transform(this.phoneNumber) };
  get phoneHref(): string { return `tel:${this.phoneNumber}` };

  constructor(
    private appStateService: AppStateService,
    private phonePipe: PhonePipe
  ) { }

  ngOnInit() {
  }

  ngOnDestroy() {
    if (this.configSub) {
      this.configSub.unsubscribe();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.phoneNumber) {
      this.phoneNumber = changes.phoneNumber.currentValue;
    }
  }

}
