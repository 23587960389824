import { Component, OnInit, ElementRef, ViewChild, AfterViewInit, Type, ComponentFactoryResolver, Input, AfterContentChecked, Directive  } from '@angular/core';
import { ContentManagementService } from '../../shared/services/content-management.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { WidgetComponent } from '../../quote/widget.component';
import { QuoteDirective } from '../../quote/directives/quote.directive';
import { DestinationEnum } from '../../quote/models/destination.enum';
import { filter } from 'rxjs/operators';
import { SanitizeHtmlPipe } from '../../shared/pipes/sanitize-html.pipe';
import { MapToIterablePipe } from '../../shared/pipes/map-to-iterable.pipe';
import { WidgetWrapperComponent } from './widget-wrapper/widget-wrapper.component';
import { NgClass, NgIf, NgFor } from '@angular/common';





@Component({
    selector: 'app-custom-landing-page',
    templateUrl: './custom-landing-page.component.html',
    styleUrls: ['./custom-landing-page.component.scss'],
    imports: [NgClass, NgIf, WidgetWrapperComponent, NgFor, MapToIterablePipe, SanitizeHtmlPipe]
})
export class CustomLandingPageComponent implements OnInit, AfterViewInit, AfterContentChecked {

  private partnernameParameter: string;
  private uriParameter: string;
  public clpContents: any;


 componentRef;
  @Input() showButton = true;

  top: boolean;
  middle: boolean;
  bottom: boolean;
  position: string;
  rowOneWidth: number;
  rowTwoWidth: number;
  rowThreeWidth: number;
  widgetOrientation: string;
  widgetOnHero: boolean;
  clpHeroImage: string;

  @ViewChild('rowOne') rowOne: ElementRef;
  @ViewChild('rowTwo') rowTwo: ElementRef;
  @ViewChild('rowThree') rowThree: ElementRef;
  constructor(private contentManagementService: ContentManagementService, private route: ActivatedRoute, private router: Router,
    private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    this.partnernameParameter = this.route.snapshot.params['partner'];
    this.uriParameter = this.route.snapshot.params['uri'];
  }

  ngAfterViewInit() {
    // Get custom HTML from CMS
    this.contentManagementService.getClpContent(this.uriParameter, this.partnernameParameter)
    .subscribe(res => {
      this.top = false;
      this.middle = false;
      this.bottom = false;
      this.position = 'none';
      this.clpContents = res;
      this.clpHeroImage = res.clpHeroImage;
      this.widgetOnHero = res.widgetOnHero;
      this.widgetOrientation = res.quoteWidgetOrientation;

      // Set horizontal widget positions
      if ( this.widgetOrientation === 'horizontal') {
        switch (this.clpContents.quoteWidgetLocation) {
          case ('top-left'):
            this.top = true;
            this.position = 'left';
            break;
          case ('top-center'):
          case ('top-right'):
            this.top = true;
            this.position = 'right';
            break;
          case ('middle-left'):
            this.middle = true;
            this.position = 'left';
            break;
          case ('middle-center'):
          case ('middle-right'):
            this.middle = true;
            this.position = 'right';
          break;
          case ('bottom-left'):
            this.bottom = true;
            this.position = 'left';
            break;
          case ('bottom-center'):
          case ('bottom-right'):
            this.bottom = true;
            this.position = 'right';
            break;
        }
      } else if (this.widgetOrientation === 'vertical') {
        // Set Vertical widget positions
        switch (this.clpContents.quoteWidgetLocation) {
          case ('top-left'):
            this.top = true;
            this.position = 'left';
            break;
          case ('top-center'):
            this.top = true;
            this.position = 'center';
            break;
          case ('top-right'):
            this.top = true;
            this.position = 'right';
            break;
          case ('middle-left'):
            this.middle = true;
            this.position = 'left';
            break;
          case ('middle-center'):
            this.middle = true;
            this.position = 'center';
            break;
          case ('middle-right'):
            this.middle = true;
            this.position = 'right';
          break;
          case ('bottom-left'):
            this.bottom = true;
            this.position = 'left';
            break;
          case ('bottom-center'):
            this.bottom = true;
            this.position = 'center';
            break;
          case ('bottom-right'):
            this.bottom = true;
            this.position = 'right';
            break;
        }
      }


    }, err => {
      this.router.navigateByUrl(this.partnernameParameter);
    });
  }

  ngAfterContentChecked() {
    if (this.rowOne) {
      this.rowOneWidth = 100 / this.rowOne.nativeElement.children.length;
      this.rowTwoWidth = 100 / this.rowTwo.nativeElement.children.length;
      this.rowThreeWidth = 100 / this.rowThree.nativeElement.children.length;
    }

  }


}
