import { Subscription } from 'rxjs';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { AppStateService } from './../../shared/services/app-state.service';
import { Component, OnInit, OnDestroy, Directive  } from '@angular/core';
import { NavigationEnum } from '../navbar/navigation.enum';
import { environment } from '../../../environments/environment';
import { NavigationLink, getFooterLinks } from '../../shared/common/navigation-links';
import { Router, NavigationEnd, NavigationCancel, RouterLink } from '@angular/router';
import { Location, NgIf, NgFor } from '@angular/common';



@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    imports: [NgIf, NgFor, RouterLink]
})
export class FooterComponent implements OnInit, OnDestroy {
  partnerName: string;
  footerContent: string;
  partnerNameSub: Subscription;
  globalContentSub: Subscription;
  agencyInfoSub: Subscription;
  agaLogoUrl: string;
  appVersion: string;
  navbarColor: string;
  path: string;
  clp: boolean;

  private _links: Array<NavigationLink> = getFooterLinks();

  get links() { return this._links; }

  getActiveLinks() { return this.links.filter(l => l.active === true); }

  constructor(
    private appStateService: AppStateService, private router: Router, private location: Location
  ) { }

  ngOnInit() {
    this.router.events.subscribe(event => {
      // we used to hide pre-footer on custom landing pages

      //if (event instanceof NavigationEnd) {
      // this.clp = this.location.path().includes('clp');
      //}
    });

    this.partnerNameSub = this.appStateService.partnerNameObservable$.subscribe(value => {
      this.partnerName = value;
    });

    this.globalContentSub = this.appStateService.globalContentObservable$.subscribe(gc => {
      this.footerContent = gc.footer;
      this.agaLogoUrl = gc.agaLogo;
    });

    this.agencyInfoSub = this.appStateService.agencyInfoObservable$.subscribe(config => {
      this.links.find(l => l.type === NavigationEnum.CoverageOptions).active = config.displayProductPage;
    });

    this.agencyInfoSub = this.appStateService.agencyInfoObservable$.subscribe(config => {
      this.navbarColor = config.colors.primaryBarColor;
    });

    this.appVersion = environment.version;

    const currentYear = new Date().getFullYear();
    document.getElementById('current-year').textContent = currentYear.toString();
  }

  ngOnDestroy() {
    this.partnerNameSub.unsubscribe();
    this.globalContentSub.unsubscribe();
    this.agencyInfoSub.unsubscribe();
    this.agencyInfoSub.unsubscribe();
  }

}
