import { Subscription } from 'rxjs';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { AppStateService } from './../../shared/services/app-state.service';
import { Component, OnInit, Input, OnDestroy, Directive  } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, NavigationEnd, RouterLink } from '@angular/router';

import { NavigationEnum } from './navigation.enum';
import { NavigationLink, getNavbarLinks } from '../../shared/common/navigation-links';
declare var jQuery: any;
declare var $: any;
import { Location, NgIf, NgFor } from '@angular/common';
import { TelephoneComponent } from '../../shared/components/telephone/telephone.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';


@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss'],
    imports: [NgIf, NgbDropdownModule, TelephoneComponent, NgFor, RouterLink]
})
export class NavbarComponent implements OnInit, OnDestroy {

  partnerName: string;
  navbarBackgroundColor: string;
  phoneNumber: string;
  navbarHoverColor: string;
  expanded: boolean = false;
  logoUrl: string;
  logoHref: string;
  partnerNameSub: Subscription;
  agencyInfoSub: Subscription;
  activeLinksSub: Subscription;
  clp: boolean;


  private _links: Array<NavigationLink> = getNavbarLinks();

  get links() { return this._links; }

  constructor(
    private appStateService: AppStateService,
    private router: Router,
    private location: Location
  ) { }

  toggleMenu() {
    if (!$('#navbarLinksJs').hasClass('collapsing')) {
      if (this.expanded === true) {
        $('.navbar-collapse').collapse('hide');
      } else {
        $('.navbar-collapse').collapse('show');
      }

      this.expanded = !this.expanded;
    }
  }

  navlinkClicked() {
    this.toggleMenu();
  }

  ngOnInit() {
    this.router.events.subscribe(event => {
      // we used to hide header and navbar on custom landing pages

      // if (event instanceof NavigationEnd) {
      // this.clp = this.location.path().includes('clp');
      //}
    });

    this.partnerNameSub = this.appStateService.partnerNameObservable$.subscribe(value => {
      this.partnerName = value;
    });

    this.agencyInfoSub = this.appStateService.agencyInfoObservable$.subscribe(config => {
      this.navbarBackgroundColor = config.colors.primaryBarColor;
      this.navbarHoverColor = config.colors.secondaryBarColor;
      this.logoUrl = config.partnerLogo;
      this.logoHref = config.logoUrl;
      this.phoneNumber = config.phoneNumber;
      this.toggleLink(NavigationEnum.CoverageOptions, config.displayProductPage);
    });

    this.activeLinksSub = this.appStateService.activeLinksObservable$.subscribe(tnl => {
      this.toggleLink(tnl.navLinkType, tnl.active);
    });

  }

  private toggleLink(navLinkType: NavigationEnum, active: boolean) {
    this.links
      .filter(l => l.modifiable === true)
      .find(l => l.type === navLinkType).active = active;
  }

  isActive(instruction: any[]): boolean {
    return this.router.isActive(this.router.createUrlTree(instruction), false);
  }

  ngOnDestroy() {
    this.partnerNameSub.unsubscribe()
    this.agencyInfoSub.unsubscribe();
    this.activeLinksSub.unsubscribe();
  }
}
