
import {map, tap} from 'rxjs/operators';
import { ContentBlock } from './../../shared/models/content-block.interface';
import { Observable } from 'rxjs';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { AppStateService } from './../../shared/services/app-state.service';
import { Component, OnInit, ElementRef, ViewChild, AfterContentChecked, Directive, Input  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';



import * as _ from 'lodash';
import { ContentBlockContainerComponent } from '../../shared/components/content-block-container/content-block-container.component';
import { ContentBlockComponent } from '../../shared/components/content-block-container/content-block/content-block.component';
import { NgIf, AsyncPipe } from '@angular/common';
import { WidgetComponent } from '../../quote/widget.component';
import { HeroImageComponent } from '../../shared/components/hero-image/hero-image.component';


@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss'],
    imports: [HeroImageComponent, WidgetComponent, NgIf, ContentBlockComponent, ContentBlockContainerComponent, AsyncPipe]
})
export class HomeComponent implements OnInit, AfterContentChecked {
  private _partnerName: string;

  public get partnerName(): string { return this._partnerName; }
  public set partnerName(val) { this._partnerName = val; }

  public firstContentBlock: Observable<ContentBlock>;
  public contentBlocks: Observable<Array<ContentBlock>>;

  /*
  * Maximum number of content blocks (not including why-buy)
  */
  public MAX_BLOCKS = 3;

  /*
  * User can confiure whether to show Get a Quote tab on a per site basis.
  */
@Input() showQuoteTab: boolean;

  /*
  * User can confiure whether to show Manage a Policy tab on a per site basis.
  */
  showPolicyTab: boolean;

  showClaimTab: boolean;

  quoteWidgetSelection: string;

  destinationSelection: string;

  /*
  * Tab background color.
  */
  nonActiveTabColor: string;

  /*
  * Tab hover color.
  */
  tabHoverColor: string;

  /*
  * Configure the text of the submit button for the Get a Quote tab.
  */
  quoteButtonText: string;

  /*
  * Quote tab submit button background color.
  */
  quoteButtonColor: string;

  /*
  * Quote tab submit button background hover color.
  */
  quoteButtonHoverColor: string;

  /*
  * Hero Image
  */
  heroImageUrl: string;

  /*
  * Nestle quote widget within hero image.
  */
  widgetOnHero: boolean;

  /*
  * Quote Tab Caption
  */
  quoteTabCaption: string;


  heroImageHeight: number;
  widgetHeight: number;

  public height: number;

  @ViewChild('heroImage') heroImage: ElementRef;
  @ViewChild('heroImageSelector') heroImageSelector: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private contentManagementService: ContentManagementService
  ) { }

  ngOnInit() {
    this.contentManagementService.tryGetAgencyConfig().subscribe(config => {
      this.nonActiveTabColor = config.colors.nonActiveTabColor;
      this.tabHoverColor = config.colors.activeTabColor;
      this.quoteButtonColor = config.colors.primaryButtonColor;
      this.quoteButtonHoverColor = config.colors.secondaryButtonColor;
      this.quoteButtonText = config.quoteButtonText;
      this.heroImageUrl = config.homeHeroImage;
      this.showPolicyTab = config.displayPolicyTab;
      this.showClaimTab = config.displayClaimTab;
      this.quoteWidgetSelection = config.quoteWidget;
      this.destinationSelection = config.destination;
      this.widgetOnHero = config.widgetOnHero;
      this.quoteTabCaption = config.quoteTabCaption;

      const sharedContentBlocks = this.contentManagementService.getContentBlocks(config.homeContents);

      this.contentBlocks = sharedContentBlocks.pipe(tap(next => {
        return next.shift();
      }));

      this.firstContentBlock = sharedContentBlocks.pipe(
        map(next => {
          if (next.length >= 1) {
            return _.first(next);
          }
        }));
    });
  }

  ngAfterContentChecked() {

    // Check to determine whether the hero image or the widget is taller,
    // Then set the min-height of the taller one
    if (this.widgetOnHero) {
      this.height = this.heroImageHeight;

      // Only check the height if it's tablet and desktop.
      if (this.height < this.widgetHeight && screen.width >= 768) {
        this.height = this.widgetHeight;
      }
    }
  }

  getImgHeight(height: number) {
    this.heroImageHeight = height;
  }

  getWidgetHeight(height: number) {
    this.widgetHeight = height;
  }
}
