
import {tap} from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Site } from './../models/site.interface';
import { ContentManagementService } from './../services/content-management.service';
import { AppStateService } from './../services/app-state.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { TimeoutService } from '../services/timeout.service';

// http://localhost:4200/hawaiianairlines/policy/checkout?bookingId=985171&quoteCode=LG6CLVIUAQFIONVQNYLKI2DCENTSUQKLXBW5RZB2OFE35VV4533WB7GWZBEBWPNLOGINEPZCY6LV4JA4DZTOJ7DRHSNTXM37M425GKY%3d&offerType=QuoteMax&productId=379871&priceOffered=19.5

export function isForwardedCheckoutUrl(route: ActivatedRouteSnapshot): boolean {

  const bookingId = route.queryParams.bookingId;
  const productId = route.queryParams.productId;
  const priceOffered = route.queryParams.priceOffered;
  const offerType = route.queryParams.offerType;
  const quoteCode = route.queryParams.quoteCode;
  const policyNumber = route.queryParams.pol;
  const purchDate = route.queryParams.purchDate;
  const partnerName = route.queryParams.partner;

  if ((bookingId && productId && priceOffered && offerType && quoteCode)
  || (policyNumber && purchDate && partnerName) || (bookingId)) {
    return true;
  }
  return false;
}

@Injectable()
export class CanActivatePolicyGuard  {

  private _partnerParameterName;

  constructor(
    private contentManagementService: ContentManagementService,
    private router: Router,
    private appStateService: AppStateService,
    private timeoutService: TimeoutService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this._partnerParameterName = route.params['partner'];
    const isForwardedCheckout: boolean = isForwardedCheckoutUrl(route);

    if ( (!(this.appStateService.getManageSessionActive()
      || this.appStateService.getPurchaseSessionActive())
      && (!isForwardedCheckout))) {
        this.router.navigateByUrl(this._partnerParameterName);
        return false;
    }

    // check if partner param is different
    // if it is, clear app state
    // if app state is cleared, set partner param in app state
    // if app state is cleared, get config and store it
    // if app state is cleared, navigate to home

    return this.contentManagementService.checkPartnerParam(this._partnerParameterName)
      .toPromise()
      .then(paramChanged => this.handlePartnerParamChanged(paramChanged))
      .then(config => this.handleAppState(config))
      .then(hasNewState => this.handleGlobalState(hasNewState))
      .then(hasNewState => this.handleRedirect(hasNewState, isForwardedCheckout))
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 404:
              this.router.navigateByUrl(environment.defaultPartnerName);
              break;
            default:
              // TODO: implement handler for failed response (500, etc.)
              break;
          }
        }
        return false;
      });
  }

  private handlePartnerParamChanged(paramChanged: boolean): Promise<Site> {

    const prom1 = new Promise<Site>((resolve, reject) => {
      if (paramChanged === true) {
        this.appStateService.clearAppState();
        return this.contentManagementService.getAgencyConfig(this._partnerParameterName)
          .toPromise()
          .then(config => { resolve(config); });
      } else {
        resolve(undefined);
      }
    });

    return prom1;
  }

  private handleAppState(config: Site): Promise<boolean> {

    const prom1 = new Promise<boolean>((resolve, reject) => {

      let hasNewState = false;

      if (config) {
        hasNewState = true;
        this.appStateService.setPartnerConfig(config);
        this.appStateService.setPartnerName(this._partnerParameterName);
      }

      this.appStateService.broadcastPartnerName();
      this.appStateService.broadcastAgencyInfo();
      this.appStateService.tryBroadcastActiveMyPoliciesLink();

      this.timeoutService.startSessionTimer();

      resolve(hasNewState);
    });

    return prom1;
  }

  private handleRedirect(hasNewState: boolean, isForwardedCheckout: boolean): Promise<boolean> {
    const prom1 = new Promise<boolean>((resolve, reject) => {
      if (hasNewState === true && !isForwardedCheckout) {
        this.router.navigateByUrl(this._partnerParameterName);
        resolve(false);
      } else {
        resolve(true);
      }
    });

    return prom1;
  }

  private handleGlobalState(hasNewState: boolean): Promise<boolean> {
    return this.contentManagementService.tryGetGlobalContent().pipe(
      tap(gc => {
        this.appStateService.broadcastGlobalContent();
      }))
      .toPromise()
      .then(() => hasNewState);
  }
}
