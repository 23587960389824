import { ProductInformation, AddOnProgramProductInformation } from './index';

export interface ProgramProduct {
    partnerBookingID: string;
    partnerQuoteID: string;
    programProductId: number;
    programId: number;
    productId: number;
    name: string;
    programEffectiveDate: string;
    price: number;
    formattedProgramEffectiveDate: string;
    programExpirationDate: string;
    formattedProgramExpirationDate: string;
    isValid: boolean;
    validationMessages: string[];
    product: ProductInformation;
    addOnProgramProducts: AddOnProgramProductInformation[];
}