import { Inject, Injectable } from '@angular/core';
import { InjectionToken } from '@angular/core';

export interface FaviconsConfig {
  icons: IconsConfig;
  cacheBusting?: boolean;
}

export interface IconsConfig {
  [ name: string ]: IconConfig;
}

export interface IconConfig {
  type: string;
  href: string;
}

export let BROWSER_FAVICONS_CONFIG = new InjectionToken<FaviconsConfig>('Favicons Config');

export abstract class Favicons {
  abstract setIcon(name: string): void;
  abstract reset(): void;
}

@Injectable()
export class BrowserFavicons {
  private elementId: string;
  private icons: IconsConfig;
  private useCacheBusting: boolean;

  constructor(@Inject(BROWSER_FAVICONS_CONFIG) config: FaviconsConfig) {
    this.elementId = 'favicon-node';
    this.icons = Object.assign(Object.create(null), config.icons);
    this.useCacheBusting = (config.cacheBusting || false);
  }

  public setIcon(name: string): void {
    if (!this.icons[name]) {
      throw(new Error(`Favicon for [${name}] not found.`));
    }

    this.setNode(this.icons[name].type, this.icons[name].href);
  }

  public reset(): void {
    this.removeNode();
  }

  // Append HTML element to HEAD
  private addNode(type: string, href: string): void {
    const linkElement = document.createElement('link');
    linkElement.setAttribute('id', this.elementId);
    linkElement.setAttribute('rel', 'icon');
    linkElement.setAttribute('type', type);
    linkElement.setAttribute('href', href);
    document.head.appendChild(linkElement);
  }

  private cacheBustingHref(href: string): string {
    const augmentedHref = (href.indexOf('?') === -1)
    ? `${href}?faviconCacheBust=${Date.now()}`
    : `${href}&faviconCacheBust=${Date.now()}`;

    return augmentedHref;
  }

  private removeNode(): void {
    const linkElement = document.head.querySelector('#' + this.elementId);
    if (linkElement) {
      document.head.removeChild(linkElement);
    }
  }

  private setNode(type: string, href: string): void {
    const augmentedHref = this.useCacheBusting ? this.cacheBustingHref(href) : href;

    this.removeNode();
    this.addNode(type, augmentedHref);
  }
}
