export interface ProductBenefitInfo {
    productBenefitId: number;
    benefitId: number;
    benefitCoverageId: number;
    benefitCode: string;
    benefitText: string;
    benefitValue: string;
    sortSequenceInProduct: number;
    covSortSequenceInProduct: number;
    benefitSortSequence: number;
    covTypeSortSequence: number;
    isMainFeature: boolean;
    hasSubLimits: boolean;
    isEndorsement: boolean;
}
