import { Directive } from '@angular/core';
import { UntypedFormControl, ValidationErrors } from '@angular/forms';
import {Observable} from 'rxjs';

@Directive({
    selector: '[appValidateDateRange]',
    standalone: true
})
export class DateRangeValidatorDirective {

  static validateDates(control: UntypedFormControl) {

    const departureDate = control.value[0];
    const returnDate = control.value[1];

    if (departureDate && returnDate === null) {
      return {departureReturnDate: false};
    }

    return null;
  }


}
