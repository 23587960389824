import { PhonePipe } from './pipes/phone.pipe';
import { ValidationService } from './services/validation.service';
import { UtilService } from './services/util.service';
import { ContentManagementService } from './services/content-management.service';
import { ContactUsService } from './components/contact-us/contact-us.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppStateService } from './services/app-state.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule } from '@angular/common';
import { NgModule, ModuleWithProviders } from '@angular/core';
import { InputValidationComponent } from './components/input-validation/input-validation.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { RouteHeaderComponent } from './components/route-header/route-header.component';
import { DynamicButtonComponent } from './components/dynamic-button/dynamic-button.component';
import { ManagePolicyComponent } from './components/manage-policy/manage-policy.component';
import { ConfirmationDialogComponent } from './components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimeoutDialogComponent } from './components/dialogs/timeout-dialog/timeout-dialog.component';
import { TimeoutService } from './services/timeout.service';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { FormValidationComponent } from './components/form-validation/form-validation.component';
import { HeroImageComponent } from './components/hero-image/hero-image.component';
import { InfoDialogComponent } from './components/dialogs/info-dialog/info-dialog.component';
import { LoadingService } from './services/loading.service';
import { ButtonComponent } from './components/button/button.component';
import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';

import { AgeDisplayDirective } from './directives/age-display.directive';
import { GoogleAnalyticsEventsService } from './services/google-analytics-events.service';
import { NgSelectModule } from '@ng-select/ng-select';
import { TelephoneComponent } from './components/telephone/telephone.component';
import { CalendarModule } from 'primeng/calendar';
import { ConfigService } from './services/config.service';
import { DateRangeValidatorDirective } from './directives/validate-date-range.directive';
import { ClosePopoverOnClickOutsideDirective } from './directives/close-popover-on-click-outside.directive';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ContentDialogComponent } from './components/dialogs/content-dialog/content-dialog.component';
import { MapToIterablePipe } from './pipes/map-to-iterable.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';

@NgModule({
    imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgxMaskDirective,
    NgSelectModule,
    CalendarModule,
    InputValidationComponent,
    RouteHeaderComponent,
    DynamicButtonComponent,
    ManagePolicyComponent,
    ConfirmationDialogComponent,
    TimeoutDialogComponent,
    DatePickerComponent,
    FormValidationComponent,
    HeroImageComponent,
    InfoDialogComponent,
    ButtonComponent,
    AgeDisplayDirective,
    PhonePipe,
    DateRangeValidatorDirective,
    ClosePopoverOnClickOutsideDirective,
    CalendarComponent,
    ContentDialogComponent,
    MapToIterablePipe,
    SanitizeHtmlPipe
],
    providers: [
        ContactUsService,
        ValidationService,
        PhonePipe,
        provideNgxMask(),
    ],
    exports: [
    InputValidationComponent,
    RouteHeaderComponent,
    DynamicButtonComponent,
    ManagePolicyComponent,
    FormValidationComponent,
    HeroImageComponent,
    DatePickerComponent,
    ButtonComponent,
    AgeDisplayDirective,
    PhonePipe,
    NgSelectModule,
    CalendarModule,
    DateRangeValidatorDirective,
    ClosePopoverOnClickOutsideDirective,
    CalendarComponent,
    MapToIterablePipe,
    SanitizeHtmlPipe
]
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [AppStateService, ContentManagementService, TimeoutService, UtilService,
                LoadingService, GoogleAnalyticsEventsService, ConfigService]
        };
    }
}
