import { InputField, ErrorMessage, InputFieldCollection } from '../../shared/models';
import { SupplierInfo } from '.';

export interface TripInformation {
    averageTripCost: number;
    currencyType: InputField<string>;
    destination: InputField<string>;
    formattedDepartureDate: InputField<string>;
    formattedFinalDepositDate: InputField<string>;
    formattedInitialDepositDate: InputField<string>;
    formattedReturnDate: InputField<string>;
    hasFinalPaymentDateQuestionBeenAnswered: boolean;
    isFinalPaymentDateRuleActive: boolean;
    numberOfTravelers: number;
    frequentFlyer: boolean;
    sumOfAverageTripCosts: number;
    supplierList: InputFieldCollection<SupplierInfo[]>;
    totalTripCost: InputField<number>;
    validationMessages: ErrorMessage[];
}
