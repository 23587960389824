import { AppStateService } from './../../shared/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Directive } from '@angular/core';
import { ContactUsComponent } from '../../shared/components/contact-us/contact-us.component';


@Component({
    selector: 'app-about-us',
    templateUrl: './about-us.component.html',
    styleUrls: ['./about-us.component.scss'],
    imports: [ContactUsComponent]
})
export class AboutUsComponent {

  public pageTitle: string = `About Us`;
  aboutUsContent: string;

  constructor(
    private route: ActivatedRoute,
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    const globalContent = this.appStateService.getGlobalContent();
    if (globalContent) {
      this.aboutUsContent = globalContent.aboutUs;
    }

    this.appStateService.globalContentObservable$.subscribe(gc => {
      this.aboutUsContent = gc.aboutUs;
    });
  }

}
