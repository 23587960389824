import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import * as _ from 'lodash';

@Injectable()
export class StaticSiteGuard  {

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {

    const partnerParameterName = route.params['partner'];

    switch (_.toLower(partnerParameterName)) {
      case 'delta':
        window.open("assets/static/delta.html", "_self");
        return false;
      case 'errors':
      window.open("allianz/error", "_self");
      return false;
    }

    return true;
  }
}
