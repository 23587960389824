import { ColorSet } from '.';
import { QuoteComponentEnum } from '../../quote/models/quote-component.enum';

export interface Site {
    partnerLogo: string;
    colors: ColorSet;
    faqs: number[];
    faqDisplayNames: string[];
    homeContents: number[];
    productContents: number[];
    accam: string;
    phoneNumber: string;
    quoteButtonText: string;
    domain: string;
    quoteWidget: QuoteComponentEnum;
    destination: string;
    widgetOnHero: boolean;
    homeHeroImage: string;
    universalHeroImage: boolean;
    displayProductPage: boolean;
    productHeroImage: string;
    productIntroContent: string;
    myPoliciesHeroImage: string;
    myPoliciesIntroContent: string;
    name: string;
    displayPolicyTab: boolean;
    displayClaimTab: boolean;
    logoUrl: string;
    address: string;
    displayName: string;
    phoneOnHeader: boolean;
    quoteTabCaption: string;
    displayEmailQuoteButton: boolean;
    useCoBrandedEmail: boolean;
    displaySupplierField: boolean;
    displayTripIDField: boolean;
    browserTabDisplayName: string;
}
