import { Component, OnInit, Input, Directive  } from '@angular/core';
import { ContentManagementService } from '../../shared/services/content-management.service';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-warning-banner',
    templateUrl: './warning-banner.component.html',
    styleUrls: ['./warning-banner.component.scss'],
    imports: [NgIf]
})
export class WarningBannerComponent implements OnInit{

  @Input() displayBanner: boolean;
  @Input() domain: string;

  constructor(private cmsService: ContentManagementService) { }

  ngOnInit() {
  }

}
