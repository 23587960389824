export interface ColorSet {
    primaryBarColor: string;
    secondaryBarColor: string;
    primaryTabColor: string;
    secondaryTabColor: string;
    primaryButtonColor: string;
    secondaryButtonColor: string;
    activeTabColor: string;
    nonActiveTabColor: string;
    primaryRecommendedColor: string;
    secondaryRecommendedColor: string;
    firstProductBuyButtonPrimaryColor: string;
    firstProductBuyButtonSecondaryColor: string;
}
