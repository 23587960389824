import { KeyValue } from '../../shared/models';

export interface ConfirmationDetails{
    policies: KeyValue[];
    formattedPurchaseDate: string;
    paymentType: string;
    cardNumber: string;
    receiptNumber: string;
    fulfillmentMethod: string;
    fulfillmentPrimaryEmail: string;
    fulfillmentSecondaryEmail: string;
    email: string;
    departureDate: string;
    returnDate: string;
    mailAddress: string;
    price: number;
}