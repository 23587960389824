export interface GadgetDetails {
    browserType: string;
    browserVersion: string;
    deviceCategory: string;
    deviceType: string;
    operatingSystem: string;
    operatingSystemVersion: string;
    requestingPlatform: string;
    requestingPlatformVersion: string;
}
