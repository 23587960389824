import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
import { QuoteService } from '../services/quote.service';
import {
  GetCarRentalQuote,
  GetTravelQuote,
  GenerateQuoteActionTypes,
  GetTravelAgeRatedQuote,
  GetEventTicketQuote,
  GetAnnualTravelQuote,
  GetRailQuote,
  GetSessionSeasonQuote
} from '../actions/generate-quote';
import {
  GetCarRentalQuoteSuccess,
  GetCarRentalQuoteFailure,
  GetTravelQuoteSuccess,
  GetTravelQuoteFailure,
  GetTravelAgeRatedQuoteSuccess,
  GetTravelAgeRatedQuoteFailure,
  GetEventTicketQuoteSuccess,
  GetEventTicketQuoteFailure,
  GetAnnualQuoteFailure,
  GetAnnualQuoteSuccess,
  GetRailQuoteSuccess,
  GetRailQuoteFailure,
  GetSessionSeasonQuoteFailure,
  GetSessionSeasonQuoteSuccess
} from '../actions/quote';


@Injectable()
export class GenerateQuoteEffects {


  getCarRentalQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetCarRentalQuote>(GenerateQuoteActionTypes.GetCarRentalQuote),
      switchMap(action =>
        this.quoteService.getCarRentalQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.departureDate,
          action.payload.returnDate
        ).pipe(
          map(quote => new GetCarRentalQuoteSuccess(quote)),
          catchError(error => of(new GetCarRentalQuoteFailure(error)))
        )
      )
    )
  );

  getTravelQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTravelQuote>(GenerateQuoteActionTypes.GetTravelQuote),
      switchMap(action =>
        this.quoteService.getTravelQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.destination,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.numTravelers,
          action.payload.frequentFlyer
        ).pipe(
          map(quote => new GetTravelQuoteSuccess(quote)),
          catchError(error => of(new GetTravelQuoteFailure(error)))
        )
      )
    )
  );

  getTravelAgeRatedQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetTravelAgeRatedQuote>(GenerateQuoteActionTypes.GetTravelAgeRatedQuote),
      switchMap(action =>
        this.quoteService.getTravelAgeRatedQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.destination,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.depositDate,
          action.payload.age,
          action.payload.ageOrDob
        ).pipe(
          map(quote => new GetTravelAgeRatedQuoteSuccess(quote)),
          catchError(error => of(new GetTravelAgeRatedQuoteFailure(error)))
        )
      )
    )
  );

  getEventTicketQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetEventTicketQuote>(GenerateQuoteActionTypes.GetEventTicketQuote),
      switchMap(action =>
        this.quoteService.getEventTicketQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.numberOfTickets,
          action.payload.eventBeginDate,
          action.payload.eventEndDate
        ).pipe(
          map(quote => new GetEventTicketQuoteSuccess(quote)),
          catchError(error => of(new GetEventTicketQuoteFailure(error)))
        )
      )
    )
  );

  getAnnualQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetAnnualTravelQuote>(GenerateQuoteActionTypes.GetAnnualTravelQuote),
      switchMap(action =>
        this.quoteService.getAnnualQuote(
          action.payload.state,
          action.payload.numTravelers,
          action.payload.destination
        ).pipe(
          map(quote => new GetAnnualQuoteSuccess(quote)),
          catchError(error => of(new GetAnnualQuoteFailure(error)))
        )
      )
    )
  );

  getRailQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetRailQuote>(GenerateQuoteActionTypes.GetRailQuote),
      switchMap(action =>
        this.quoteService.getRailQuote(
          action.payload.state,
          action.payload.totalTripCost,
          action.payload.departureDate,
          action.payload.returnDate,
          action.payload.numTravelers
        ).pipe(
          map(quote => new GetRailQuoteSuccess(quote)),
          catchError(error => of(new GetRailQuoteFailure(error)))
        )
      )
    )
  );

  getSessionSeasonQuote$ = createEffect(() =>
    this.actions$.pipe(
      ofType<GetSessionSeasonQuote>(GenerateQuoteActionTypes.GetSessionSeasonQuote),
      switchMap(action =>
        this.quoteService.getEventTicketQuote(
          action.payload.state,
          action.payload.cost,
          action.payload.numberOfPasses,
          action.payload.beginDate,
          action.payload.endDate
        ).pipe(
          map(quote => new GetSessionSeasonQuoteSuccess(quote)),
          catchError(error => of(new GetSessionSeasonQuoteFailure(error)))
        )
      )
    )
  );


  constructor(private actions$: Actions, private quoteService: QuoteService) {}
}
