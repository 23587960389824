import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { ESitesNgbDateParserFormatter } from './../../quote/services/date-parser-formatter';
import { Directive, SimpleChanges, Input, OnInit } from '@angular/core';
import { ElementRef } from '@angular/core';
import { OnChanges } from '@angular/core';
import { dateOfBirthToAge } from '../common/date-of-birth-to-age';


@Directive({
    selector: '[appAgeDisplay]',
    standalone: true
})
export class AgeDisplayDirective implements OnInit, OnChanges {

  private _validatePattern = /^\d{2}\/\d{2}\/\d{4}$/;

  @Input() initialVal: string = '';
  @Input() dateOfBirth: string = '';

  constructor(
    private elementRef: ElementRef,
    private _dateFormatter: ESitesNgbDateParserFormatter
  ) { }

  ngOnInit() {
    if (this.initialVal) {
      this.setAge(this.initialVal);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.dateOfBirth) {
      this.setAge(this.formatAge(changes.dateOfBirth.currentValue));
    }
  }

  private formatAge(date: string): string {
    let formattedAge: string = '';

    if (this._validatePattern.test(date)) {
      try {
        const ageStruct: NgbDateStruct = this._dateFormatter.parse(date);

        if (ageStruct) {
          const ageJsDate = new Date(ageStruct.year, ageStruct.month - 1, ageStruct.day);
          formattedAge = this.calculateAge(ageJsDate).toString();
        }

      } catch (e) { formattedAge = ''; }
    }

    return formattedAge;
  }

  private setAge(formattedAge: string): void {
    this.elementRef.nativeElement.innerHTML = `[Age: ${formattedAge}]`;
  }

  private calculateAge(dateOfBirth: Date): number {
    return dateOfBirthToAge(dateOfBirth);
  }
}
