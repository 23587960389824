import { ColorSet } from '.';

export interface GlobalContent {
    aboutUs: string;
    footer: string;
    agaLogo: string;
    quoteTabCaption: string;
    policyTabCaption: string;
    claimTabCaption: string;
    destinationImageSet: DestinationImageSet;
    colors: ColorSet;
    productPageHeroImageSet: ProductPageHeroImageSet;
}

export interface DestinationImageSet {
    destinations: Array<{dest: string, url: string}>;
}

export interface ProductPageHeroImageSet {
    productPageHeroImages: Array<{dest: string, url: string}>;
}
