import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
    selector: '[appQuote]',
    standalone: true
})
export class QuoteDirective {

  constructor(public viewContainerRef: ViewContainerRef) { }

}
