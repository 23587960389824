import { AppStateService } from './../../services/app-state.service';
import { FormControl, ReactiveFormsModule } from '@angular/forms';
import { Validators } from '@angular/forms';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Component, OnInit, Directive } from '@angular/core';
import { KeyValue } from '../../models';
import { ContactUsService } from './contact-us.service';
import { UtilService } from '../../services/util.service';
import { AlertService } from '../../alert/services/alert.service';
import * as AlertMessages from '../../constants/alert-messages.constants';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { Alert } from '../../../shared/actions/alert';
import { AlertState } from '../../../shared/reducers';
import { AlertTypeEnum } from '../../../shared/alert/alert-type.enum';
import { ContentManagementService } from '../../services/content-management.service';
import { ButtonEnum } from '../../../quote/models/button.enum';
import { GoogleAnalyticsEventsService } from '../../services/google-analytics-events.service';
import { MatButtonModule } from '@angular/material/button';
import { NgFor, AsyncPipe } from '@angular/common';
import { InputValidationComponent } from '../input-validation/input-validation.component';
import { TelephoneComponent } from '../telephone/telephone.component';
import { MatCardModule } from '@angular/material/card';

declare var grecaptcha: any;


@Component({
    selector: 'app-contact-us',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss'],
    imports: [MatCardModule, ReactiveFormsModule, TelephoneComponent, InputValidationComponent, NgFor, MatButtonModule, AsyncPipe]
})
export class ContactUsComponent implements OnInit {
  public contactUsForm: UntypedFormGroup;
  public subjectSelections: Observable<KeyValue[]>;
  public phoneNumber: string;
  primaryButtonColor: string;
  secondaryButtonColor: string;
  hovering: boolean;
  recaptchaToken: string;
  captcha_sc: string = '6LeyxzcpAAAAAIWriyOa-P6LowAsrwLe9oc3-MsP';
  buttonDisabled = false;

  get name() { return this.contactUsForm.get('name'); }
  get email() { return this.contactUsForm.get('email'); }
  get policyNumber() { return this.contactUsForm.get('policyNumber'); }
  get subject() { return this.contactUsForm.get('subject'); }
  get question() { return this.contactUsForm.get('question'); }

  constructor(
    private formBuilder: UntypedFormBuilder,
    private contactUsService: ContactUsService,
    private utilService: UtilService,
    private alertService: AlertService,
    private appStateService: AppStateService,
    private store: Store<AlertState>,
    private cmsService: ContentManagementService,
    private ga: GoogleAnalyticsEventsService
) {
    this.contactUsForm = this.formBuilder.group({
      name: ['', Validators.required],
      email: ['', Validators.required],
      policyNumber: '',
      subject: ['', Validators.required],
      question: ['', Validators.required]
    });
  }

  ngOnInit() {

    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=${this.captcha_sc}`;
    script.async = true;
    document.head.appendChild(script);

    this.subjectSelections = this.contactUsService.getContactUsSubjects();
    this.cmsService.tryGetAgencyConfig().subscribe(config => {
      this.phoneNumber = config.phoneNumber;
      this.primaryButtonColor = config.colors.primaryButtonColor;
      this.secondaryButtonColor = config.colors.secondaryButtonColor;
    });
  }

  public sendForm(tokenGValue: string) {

    this.contactUsService.sendContactRequest({
      name: this.name.value,
      email: this.email.value,
      policyNumber: this.policyNumber.value,
      subject: this.subject.value,
      question: this.question.value,
      tokenG: tokenGValue
    }).subscribe(
      success => {
        this.buttonDisabled = false;
        this.ga.formSubmit(ButtonEnum.ContactUs);
        this.store.dispatch(
          new Alert({
            message: AlertMessages.CONTACT_SUCCESS,
            alertType: AlertTypeEnum.SUCCESS
          })
        );
      },
      err => {
        this.buttonDisabled = false;
        this.store.dispatch(
          new Alert({
            message: AlertMessages.CONTACT_FAILED,
            alertType: AlertTypeEnum.ERROR
          })
        );
      });

  }
    onSubmit() {

    if (this.contactUsForm.valid) {
      this.buttonDisabled = true;
      grecaptcha.ready(() => {
        grecaptcha.execute(this.captcha_sc, { action: 'submit' })
          .then((tokenG) => {
            //console.log(tokenG);
            this.sendForm(tokenG);
          });
      });
    }
    else {
      this.utilService.validateAllFormFields(this.contactUsForm);
    }


  }

}
