import { InputField, ErrorMessage } from '../../shared/models';
import { PrimaryTraveler, TravelerInformation } from '.';

export interface PassHolderInformation {
    beneficiary: string;
    membershipNumber: string;
    primaryTraveler: PrimaryTraveler;
    psBOID: string;
    otherTravelers: TravelerInformation[];
    usePrimaryAddress: boolean;
    validationMessages: ErrorMessage[];
}

