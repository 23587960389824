import { ErrorMessage } from '../../shared/models';
import { Booking, PolicyListing } from '.';

export interface Policy {
    policyListing: PolicyListing;
    booking: Booking;
    showMessageBeforeCancel: string;
    canBeModified: boolean;
    canBeCanceled: boolean;
    canFileAClaim: boolean;
    isValid: boolean;
    errorMessages: ErrorMessage[];
    message: string;
    transactionNumber: string;
    transactionStatus: string;
}