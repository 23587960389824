import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.interface';
import * as fromBooking from './booking';
import * as fromReport from './report';

export * as fromPolicy from './booking';


export interface BookingState {
  booking: fromBooking.State;
  report: fromReport.State;
}

export interface State extends AppState {
  booking: BookingState;
  report: BookingState;
}

export const reducers = {
  booking: fromBooking.reducer,
  report: fromReport.reducer
};

export const bookingState = createFeatureSelector<BookingState>('booking');

export const reportState = createFeatureSelector<BookingState>('report');

export const emailEntityState = createSelector(
  bookingState,
  state => state.booking
);

export const reportEntityState = createSelector(
  reportState,
  state => state.report
);
