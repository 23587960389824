import { enableProdMode, ErrorHandler, importProvidersFrom } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GenerateQuoteEffects } from './app/quote/effects/generate-quote';
import { AppEffects } from './app/effects/app.effects';
import { EffectsModule } from '@ngrx/effects';
import { appReducer, appMetaReducers } from './app/reducers/app.reducer';
import { StoreModule } from '@ngrx/store';
import { AlertModule } from './app/shared/alert/alert.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app/app-routing.module';
import { SharedModule } from './app/shared/shared.module';
import { QuoteModule } from './app/quote/quote.module';
import { ReactiveFormsModule } from '@angular/forms';
import { provideAnimations } from '@angular/platform-browser/animations';
import { CustomSerializer } from './app/shared/utils';
import { RouterStateSerializer, routerReducer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { AuthInterceptor } from './app/shared/interceptors/auth.interceptor';
import { HTTP_INTERCEPTORS, withInterceptorsFromDi, provideHttpClient } from '@angular/common/http';
import { GlobalErrorHandler } from './app/shared/services/global-error-handler';
import { Favicons, BrowserFavicons, BROWSER_FAVICONS_CONFIG } from './app/favicons';
import { Title, bootstrapApplication } from '@angular/platform-browser';
import { CanActivateClpGuard } from './app/shared/guards/can-activate-clp.guard';
import { CanDeactivatePrivacyGuard } from './app/shared/guards/can-deactivate-privacy.guard';
import { CanDeactivateGuard } from './app/shared/guards/can-deactivate.guard';
import { CurrencyPipe, CommonModule } from '@angular/common';
import { StaticSiteGuard } from './app/shared/guards/static-site.guard';
import { CanRedirectGuard } from './app/shared/guards/can-redirect.guard';
import { CanActivatePolicyGuard } from './app/shared/guards/can-activate-policy.guard';
import { CoverageOptionsGuard } from './app/shared/guards/coverage-options.guard';
import { CanActivateGuard } from './app/shared/guards/can-activate.guard';
import { CryptoService } from './app/shared/services/crypto.service';

// import * as fromQuote from './quote/reducers';
// import * as fromPolicy from './policy/reducers';
// import * as fromShared from './shared/reducers';
import { fromQuote } from './app/quote/reducers';
import { fromPolicy } from './app/policy/reducers';
import { fromShared } from './app/shared/reducers';



if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        importProvidersFrom(CommonModule, ReactiveFormsModule, QuoteModule.forRoot(), SharedModule.forRoot(), AppRoutingModule, NgbModule, AlertModule.forRoot(), StoreModule.forRoot(appReducer, {
            metaReducers: appMetaReducers,
        }), StoreModule.forRoot({
            router: routerReducer
        }),
        StoreModule.forFeature('quote', fromQuote.reducer),
        StoreModule.forFeature('policy', fromPolicy.reducer),
        StoreModule.forFeature('shared', fromShared.reducer),
        StoreRouterConnectingModule.forRoot({
            stateKey: 'router'
        }), EffectsModule.forRoot([AppEffects]), EffectsModule.forFeature([GenerateQuoteEffects]), !environment.production ? StoreDevtoolsModule.instrument() : []),
        CryptoService,
        CanActivateGuard,
        CoverageOptionsGuard,
        CanActivatePolicyGuard,
        CanRedirectGuard,
        StaticSiteGuard,
        CurrencyPipe,
        CanDeactivateGuard,
        CanDeactivatePrivacyGuard,
        CanActivateClpGuard,
        Title,
        { provide: Favicons, useClass: BrowserFavicons },
        { provide: BROWSER_FAVICONS_CONFIG,
            useValue: {
                icons: {
                    'none': {
                        type: 'image/x-icon',
                        href: '#'
                    },
                    'allianz': {
                        type: 'image/x-icon',
                        href: '/icons/allianz.ico'
                    },
                    'partner': {
                        type: 'image/png',
                        href: '/icons/eTravel_Favicon.png'
                    }
                },
                // Determine whether or not a random token is auto-appended to the HREF values
                // whenever an icon is injected into the document
                cacheBusting: true
            },
        },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
        { provide: RouterStateSerializer, useClass: CustomSerializer },
        provideAnimations(),
        provideHttpClient(withInterceptorsFromDi())
    ]
});
