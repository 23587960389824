import { Subscription } from 'rxjs';
import { AppStateService } from './../../shared/services/app-state.service';
import { Component, OnInit, OnDestroy, Directive  } from '@angular/core';
import * as _ from 'lodash';
import { TelephoneComponent } from '../../shared/components/telephone/telephone.component';
import { RouterLink } from '@angular/router';
import { NgIf } from '@angular/common';


@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    imports: [NgIf, RouterLink, TelephoneComponent]
})
export class HeaderComponent implements OnInit, OnDestroy {

  partnerName: string;
  phoneNumber: string;
  logoUrl: string;
  logoHref: string;
  agaLogoUrl: string;
  showPhone: boolean = true;

  partnerNameSub: Subscription;
  agencyInfoSub: Subscription;
  globalContentSub: Subscription;

  constructor(
    private appStateService: AppStateService
  ) { }

  ngOnInit() {
    this.partnerNameSub = this.appStateService.partnerNameObservable$.subscribe(value => {
      this.partnerName = value;
    });

    this.agencyInfoSub = this.appStateService.agencyInfoObservable$.subscribe(config => {
      this.logoUrl = config.partnerLogo;
      this.logoHref = config.logoUrl;
      this.showPhone = config.phoneOnHeader;
      this.phoneNumber = config.phoneNumber;
    });

    this.globalContentSub = this.appStateService.globalContentObservable$.subscribe(gc => {
      this.agaLogoUrl = gc.agaLogo;
    });
  }

  ngOnDestroy() {
    this.partnerNameSub.unsubscribe();
    this.agencyInfoSub.unsubscribe();
    this.globalContentSub.unsubscribe();
  }
}
