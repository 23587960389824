import { Faq } from './../../shared/models/faq.interface';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { ContactUsService } from './../../shared/components/contact-us/contact-us.service';
import { AppStateService } from './../../shared/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Directive } from '@angular/core';
import * as _ from 'lodash';
import { GET_FAQ_FAILED } from '../../shared/constants/alert-messages.constants';
import { AlertTypeEnum } from '../../shared/alert/alert-type.enum';
import { Alert } from '../../shared/actions/alert';
import { Store } from '@ngrx/store';
import { AlertState } from '../../shared/reducers';
import { ContactUsComponent } from '../../shared/components/contact-us/contact-us.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgFor, NgIf } from '@angular/common';


@Component({
    selector: 'app-faq',
    templateUrl: './faq.component.html',
    styleUrls: ['./faq.component.scss'],
    imports: [NgFor, MatExpansionModule, NgIf, ContactUsComponent]
})
export class FaqComponent implements OnInit {

  public pageTitle: string = `Frequently Asked Questions`;
  public firstHeader: string;
  faq: Array<Faq>;
  private primaryBarColor: string;
  faqCategories: string[];

  constructor(
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private contactUsService: ContactUsService,
    private contentManagementService: ContentManagementService,
    private store: Store<AlertState>,
  ) { }

  ngOnInit() {
    this.contentManagementService.tryGetAgencyConfig().subscribe(next => {
      this.primaryBarColor = next.colors.primaryBarColor;
      this.faqCategories = next.faqDisplayNames;
      this.contentManagementService.getFaqs(next.faqs, next.name).subscribe(faqs => {
        this.firstHeader = _.first(faqs).displayName;
        this.faq = faqs;
      }, err => {
        this.store.dispatch(
          new Alert({
            message: GET_FAQ_FAILED,
            alertType: AlertTypeEnum.ERROR
          }));
      });
    });

  }

  scrollToCategory(category: string) {
    const element = document.getElementById(category);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  scrollToTop() {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }  

}
