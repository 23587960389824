
import {tap} from 'rxjs/operators';
import { environment } from './../../../environments/environment';
import { HttpErrorResponse } from '@angular/common/http';
import { Site } from './../models/site.interface';
import { ContentManagementService } from './../services/content-management.service';
import { AppStateService } from './../services/app-state.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';



@Injectable()
export class CanActivateClpGuard  {

  private _partnerParameterName;
  private _uriParameterName;

  constructor(
    private contentManagementService: ContentManagementService,
    private router: Router,
    private appStateService: AppStateService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this._partnerParameterName = route.params['partner'];
    this._uriParameterName = route.params['uri'];

    // check if partner param is different
    // if it is, clear app state
    // if app state is cleared, set partner param in app state
    // if app state is cleared, get config and store it
    // if app state is cleared, navigate to home

    return this.contentManagementService.checkPartnerParam(this._partnerParameterName)
      .toPromise()
      .then(paramChanged => this.handlePartnerParamChanged(paramChanged))
      .then(config => this.handleAppState(config))
      .then(hasNewState => this.handleGlobalState(hasNewState))
      .then(hasNewState => this.handleRedirect(hasNewState))
      .catch(err => {
        if (err instanceof HttpErrorResponse) {
          switch (err.status) {
            case 404:
              this.router.navigateByUrl(environment.defaultPartnerName);
              break;
            default:
              // TODO: implement handler for failed response (500, etc.)
              break;
          }
        }
        return false;
      });
  }

  private handlePartnerParamChanged(paramChanged: boolean): Promise<Site> {

    const prom1 = new Promise<Site>((resolve, reject) => {
      if (paramChanged === true) {
        this.appStateService.clearAppState();
        return this.contentManagementService.getAgencyConfig(this._partnerParameterName)
          .toPromise()
          .then(config => { resolve(config); });
      } else {
        resolve(undefined);
      }
    });

    return prom1;
  }

  private handleAppState(config: Site): Promise<boolean> {

    const prom1 = new Promise<boolean>((resolve, reject) => {

      let hasNewState = false;

      if (config) {
        hasNewState = true;
        this.appStateService.setPartnerConfig(config);
        this.appStateService.setPartnerName(this._partnerParameterName);
      }

      this.appStateService.broadcastPartnerName();
      this.appStateService.broadcastAgencyInfo();
      this.appStateService.tryBroadcastActiveMyPoliciesLink();

      resolve(hasNewState);
    });

    return prom1;
  }

  private handleRedirect(hasNewState: boolean): Promise<boolean> {

    const prom1 = new Promise<boolean>((resolve, reject) => {
      if (hasNewState === true && this._uriParameterName === null) {
        this.router.navigateByUrl(this._partnerParameterName);
        resolve(false);
      } else {
        resolve(true);
      }
    });

    return prom1;
  }

  private handleGlobalState(hasNewState: boolean): Promise<boolean> {
    return this.contentManagementService.tryGetGlobalContent().pipe(
      tap(gc => {
        this.appStateService.broadcastGlobalContent();
      }))
      .toPromise()
      .then(() => hasNewState);
  }
}
