import { ContentBlock } from '../../../models/content-block.interface';
import { Component, Input, OnInit, Directive  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NgIf, NgClass } from '@angular/common';


@Component({
    selector: 'app-content-block',
    templateUrl: './content-block.component.html',
    styleUrls: ['./content-block.component.scss'],
    imports: [NgIf, NgClass]
})
export class ContentBlockComponent implements OnInit  {

  @Input() content: ContentBlock;
  header: any;
  contentBlock: any;

  constructor(private sanitizer: DomSanitizer) {

   }

   ngOnInit() {
    this.header = this.sanitizeHTML(this.content.header);
    this.contentBlock = this.sanitizeHTML(this.content.content);
   }

   // Allow HTML and CSS from CMS
   sanitizeHTML(content) {
     return this.sanitizer.bypassSecurityTrustHtml(content);
   }
}

