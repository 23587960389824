import { InputField, ErrorMessage } from '../../shared/models';
import { TravelerInformation, PrimaryTraveler } from '.';

export interface TravelInformation {
    beneficiary: string;
    membershipNumber: string;
    otherTravelers: TravelerInformation[];
    primaryTraveler: PrimaryTraveler;
    psBOID: string;
    usePrimaryAddress: boolean;
    validationMessages: ErrorMessage[];
}
