import { SimpleChanges } from '@angular/core';
import { Component, OnInit, ViewEncapsulation, Input, Directive  } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
    imports: [MatButtonModule]
})
export class ButtonComponent implements OnInit {

  @Input() type: string = `button`;
  @Input() disabled: boolean = false;
  @Input() color: ButtonTypeEnum = ButtonTypeEnum.Primary;
  @Input() primaryButtonColor: string;
  @Input() secondaryButtonColor: string;

  hovering: boolean;

  constructor() { }

  ngOnInit() { }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.disabled) {
      this.disabled = changes.disabled.currentValue;
    }
  }

}

export enum ButtonTypeEnum {
  Primary = 1,
  Secondary
}
