import { UntypedFormControl } from '@angular/forms';
import { Component, OnInit, Input, Directive  } from '@angular/core';
import { NgIf } from '@angular/common';

/*
*   This component is designed to consolidate validation logic that is standard
*   on forms.  It also accommodates custom validation messages by passing them
*   inside the selector markup.
*
*   Usage:
*   <app-input-validation [inputFormControl]="eventStartDate">
*      <div class="validation-error__message" *ngIf="eventStartDate.errors?.ngbDate?.invalid">Please enter a valid date.</div>
*      <div class="validation-error__message" *ngIf="eventStartDate.errors?.ngbDate?.requiredBefore">Event start date must be on or after today.</div>
*   </app-input-validation>
*/


@Component({
    selector: 'app-input-validation',
    templateUrl: './input-validation.component.html',
    styleUrls: ['./input-validation.component.scss'],
    imports: [NgIf]
})
export class InputValidationComponent implements OnInit {

  @Input() inputFormControl: UntypedFormControl;

  constructor() { }

  ngOnInit() {
    if (!this.inputFormControl) throw new Error(`Attribute 'inputFormControl' is required.`);
  }

}
