import { Component, OnInit, Input, ViewChild, Directive  } from '@angular/core';
import { QuoteService } from '../../services/quote.service';
import { UtilService } from '../../../shared/services/util.service';
import { SelectListItem } from '../../../shared/models';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder, Validators, ReactiveFormsModule } from '@angular/forms';

import { Router, ActivatedRoute } from '@angular/router';
import { AppStateService } from '../../../shared/services/app-state.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ESitesNgbDateParserFormatter } from '../../services/date-parser-formatter';
import { NgbDateParserFormatter, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import * as AlertMessages from '../../../shared/constants/alert-messages.constants';
import { ToolTipConstants } from '../../../shared/constants/tool-tip-constants';
import { Store } from '@ngrx/store';
import { QuoteState } from '../../reducers';
import { GetRailQuote } from '../../actions/generate-quote';
import * as moment from 'moment';
import { Actions, ofType } from '@ngrx/effects';
import { QuoteActionTypes } from '../../actions/quote';
import { ButtonEnum } from '../../models/button.enum';
import { GoogleAnalyticsEventsService } from '../../../shared/services/google-analytics-events.service';
import { map } from 'rxjs/operators';
import { RailFormData } from '../../models/form-data.interface';
import { DynamicButtonComponent } from '../../../shared/components/dynamic-button/dynamic-button.component';
import { MatButtonModule } from '@angular/material/button';
import { SharedModule } from 'primeng/api';
import { CalendarModule } from 'primeng/calendar';
import { CalendarComponent } from '../../../shared/components/calendar/calendar.component';
import { InputValidationComponent } from '../../../shared/components/input-validation/input-validation.component';
import { NgFor, NgIf } from '@angular/common';


@Component({
    selector: 'app-rail',
    templateUrl: './rail.component.html',
    styleUrls: ['./rail.component.scss'],
    providers: [
        { provide: NgbDateParserFormatter, useClass: ESitesNgbDateParserFormatter },
        ESitesNgbDateParserFormatter
    ],
    imports: [ReactiveFormsModule, NgFor, InputValidationComponent, NgbTooltipModule, NgIf, CalendarComponent, CalendarModule, SharedModule, MatButtonModule, DynamicButtonComponent]
})
export class RailComponent implements OnInit {

  MAX_NUM_TRAVELERS = 9;

  railForm: UntypedFormGroup;
  states: SelectListItem[];
  errors: String[] = [];
  hasErrors = false;
  buttonDisabled = false;
  partnerName: string;
  tripCostTooltipMessage: string = ToolTipConstants.totalTripCost;
  displayMonths: number;
  minDateTomorrow: Date;

  @Input() quoteButtonText;
  @Input() quoteButtonColor;
  @Input() quoteButtonHoverColor;
  @Input() componentId: string;
  @ViewChild('rangeDatePicker', { static: true }) rangeDatePicker;

  constructor(
    private quoteService: QuoteService,
    private formBuilder: UntypedFormBuilder,
    private utilService: UtilService,
    private _dateFormatter: ESitesNgbDateParserFormatter,
    private router: Router,
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private store: Store<QuoteState>,
    private action$: Actions,
    private ga: GoogleAnalyticsEventsService
  ) {
    this.railForm = this.formBuilder.group({
      departureReturnDate: [''],
      totalTripCost: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(/^(\d+|\d+\.\d{1,2})$/)
      ]),
      state: ['', Validators.required],
      numTravelers: [1, Validators.required]
    });
  }

  ngOnInit() {
    this.utilService.getStates().subscribe(states => {
      this.states = states;
    });

    this.partnerName = this.route.snapshot.params['partner'];

    const sessionQuoteForm = this.appStateService.getQuoteForm() as RailFormData;

    if (typeof (sessionQuoteForm) !== 'undefined' && sessionQuoteForm !== null) {
      // Session Storage stores all values as string. The Departure and Return date will need to be convert it to
      // Date objects before the calendar can parse it.
      const departureReturnDate = [];
      // Departure Date
      departureReturnDate.push(new Date(sessionQuoteForm.departureDate));
      // Return Date
      departureReturnDate.push(new Date(sessionQuoteForm.returnDate));

      this.railForm.patchValue({
        departureReturnDate: departureReturnDate,
        numTravelers: sessionQuoteForm.numTravelers,
        state: sessionQuoteForm.state,
        totalTripCost: sessionQuoteForm.totalTripCost
      });
    }

    this.displayMonths = window.innerWidth >= 768
      ? 2
      : 1;

    this.tomorrow();
  }

  onSubmit() {
    this.hasErrors = false;
    this.errors = [];
    this.setDateValidation();

    if (this.railForm.valid) {
      this.buttonDisabled = true;
      const quoteFormData: RailFormData = {
        state: this.state.value,
        totalTripCost: this.totalTripCost.value as number,
        departureDate: moment(this.departureReturnDate.value[0]).format('MM/DD/YYYY'),
        returnDate: moment(this.departureReturnDate.value[1]).format('MM/DD/YYYY'),
        numTravelers: this.numTravelers.value as number,
      }
      this.store.dispatch(
        new GetRailQuote(quoteFormData)
      );

      this.action$.pipe(
        ofType<any>(QuoteActionTypes.GetRailQuoteSuccess),
        map(action => action.payload)
      ).subscribe(q => {
          this.buttonDisabled = false;
          this.ga.formSubmit(ButtonEnum.GetQuote);
          this.appStateService.clearManagePolicyState();
          this.appStateService.clearPurchaseState();
          this.appStateService.setPurchaseSessionActive(true);
          this.appStateService.setQuoteForm(quoteFormData);
          this.appStateService.setQuote(q);
          this.appStateService.broadcastQuote(q);
          this.router.navigateByUrl(`${this.partnerName}/policy/products`);
        });

        this.action$.pipe(
          ofType<any>(QuoteActionTypes.GetRailQuoteFailure),
          map(action => action.payload)
        ).subscribe((err: HttpErrorResponse) => {
          this.hasErrors = true;
          if (err.status === 400) {
            this.errors = this.utilService.getModelStateErrors(
              err.error.modelState
            );
          }
          if (this.errors.length === 0) {
            this.errors.push(AlertMessages.SERVICE_DOWN);
          }
          this.buttonDisabled = false;
        });
    } else {
      this.utilService.validateAllFormFields(this.railForm);
    }
  }

  onClose() {
    this.setDateValidation();
    this.utilService.closeModal(this.rangeDatePicker);
  }

  onSelect() {
    // Close datepicker if end date is selected.
    if (this.rangeDatePicker.value[1]) {
      this.rangeDatePicker.overlayVisible = false;
    }
  }

  setDateValidation() {
    if (!this.rangeDatePicker.value[0]) {
      this.railForm.controls['departureReturnDate'].setErrors(Validators.required);
    }
  }

  tomorrow() {
    const d = new Date();
    d.setDate(d.getDate() + 1);
    this.minDateTomorrow = d;
  }


  get departureReturnDate() {
    return this.railForm.get('departureReturnDate');
  }
  get totalTripCost() { return this.railForm.get('totalTripCost'); }
  get state() { return this.railForm.get('state'); }
  get numTravelers() { return this.railForm.get('numTravelers'); }

  get numTravelersArray(): number[] {
    const arr = [];
    let i = 1;
    while (arr.push(i++) < this.MAX_NUM_TRAVELERS) { }
    return arr;
  }

}
