import { Directive, OnInit, OnDestroy, ElementRef, NgZone, ChangeDetectorRef, Renderer2, ComponentRef, HostListener } from '@angular/core';
import { NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import { NgbPopoverWindow } from '@ng-bootstrap/ng-bootstrap/popover/popover';

@Directive({
    selector: '[appClosePopoverOnClickOutside][ngbPopover]',
    standalone: true
})
export class ClosePopoverOnClickOutsideDirective {


  constructor(private elementRef: ElementRef, private ngbPopover: NgbPopover) { }


 @HostListener('document:click', ['$event'])
 private documentClicked(event: MouseEvent): void {
    // Check if popover is opened
    if (this.ngbPopover && this.ngbPopover.isOpen()) {
      // make sure the inside of the popover content is not being clicked on
      if (!this.elementRef.nativeElement.contains(event.target)) {
        const popoverWindowRef: ComponentRef<NgbPopoverWindow> = (this.ngbPopover as any)._windowRef;
        // Close popover on click outside
        if (!popoverWindowRef.location.nativeElement.contains(event.target)) {
          this.ngbPopover.close();
        }
      }
    }
 }
}


