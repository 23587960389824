export interface TravelSegmentType {
    arrivalDate: string;
    classOfService: string;
    connectionDuration: number;
    departureDate: string;
    destination: DestinationType;
    distanceTraveled: number;
    fareClass: string;
    isConnectingSegment: boolean;
    isDomestic: boolean;
    origin: OriginType;
    routeNumber: string;
    travelDuration: number;
}

export interface OriginType {
    city: string;
    code: string;
    country: string;
    region: string;
}

export interface DestinationType {
    city: string;
    code: string;
    country: string;
    region: string;
}
