<div class="input-group input-group-sm">
    <ng-template #customDay let-date="date" let-currentMonth="currentMonth" let-selected="selected" let-disabled="disabled" let-focused="focused">
        <div class="custom-day btn-light"
              [class.focused]="focused"
              [class.bg-primary]="selected"
              [class.text-white]="selected"
              [class.hidden]="date.month !== currentMonth"
              [class.text-muted]="disabled"
              [class.outside]="!selected && (date.month !== currentMonth || disabled)"
              [class.active]="isActive(date)">
          {{ date.day }}
        </div>
      </ng-template>
  <input
  [attr.id]="labelForId"
  (blur)="onBlur(dateVal)"
  [mask]="mask"
  class="form-control"
  [minDate]="minDate"
  [maxDate]="maxDate"
  [displayMonths]="displayMonths"
  [placeholder]="dateVal ? dateVal : 'MM/DD/YYYY'"
  ngbDatepicker
  [firstDayOfWeek]= 7
  #d="ngbDatepicker"
  [value]="dateVal"
  [placement]="['bottom-left', 'top-left']"
  (input)="onInputChanged($event)"
  [disabled]="disabled"
  [dayTemplate]="customDay"/>
    <button aria-label="Date picker" *ngIf="isDateOfBirth === false" [disabled]="disabled || validationErrors" class="input-group-addon" (click)="d.toggle()" type="button">
    <span class="fa fa-calendar fa-fw"></span>
  </button>
</div>
<div *ngIf="validationErrors && !disabled" class="validation-error">
  <div class="validation-error__message" *ngIf="validationErrors?.ngbDate?.pattern">Please enter a valid date format MM/DD/YYYY</div>
  <div class="validation-error__message" *ngIf="validationErrors?.ngbDate?.invalid">Please enter a valid date.</div>
  <div class="validation-error__message" *ngIf="validationErrors?.ngbDate?.requiredBefore">Please enter a date on or after {{getRequiredBeforeError()}}.</div>
  <div class="validation-error__message" *ngIf="validationErrors?.ngbDate?.requiredAfter">Please enter a date on or before {{getRequiredAfterError()}}.</div>
</div>
