import { InputField, ErrorMessage } from '../../shared/models';

export interface SecondChanceInformation {
    offerType: string;
    productOffered: string;
    priceOffered: string;
    isSCEnabled: InputField<boolean>;
    validationMessages: ErrorMessage[];
    isSCAllowed: boolean;
}
