export interface Report {
    errorMessages: Array<string>;
    isValid: boolean;
    message: string;
    transactionNumber: number;
    transactionStatus: string;
    fileName: string;
    fileType: number;
    fileContent: string;
    isEmailSendSuccessful: boolean;
}
