import { Component, OnInit, ViewEncapsulation, Input, EventEmitter, Output, ViewChild, ElementRef, AfterViewInit, Directive  } from '@angular/core';
import { NgIf } from '@angular/common';

// See _responsive-breakpoints.scss to get the breakpoint value.
// Currently will only show on medium (768px) devices and up.


@Component({
    selector: 'app-hero-image',
    templateUrl: './hero-image.component.html',
    styleUrls: ['./hero-image.component.scss'],
    imports: [NgIf]
})
export class HeroImageComponent implements OnInit, AfterViewInit {

  @Input() src;
  @Input() nestled: boolean;
  @Input() altName: string;
  @Output() getImgHeight = new EventEmitter<number>();
  @ViewChild('heroImage') heroImage: ElementRef;

  constructor() { }

  nonMobile() {
    return screen.width >= 768;
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    // If there's an img src, get the height of the image.
    if (this.src && screen.width >= 768) {
      setTimeout(() => {
        this.getImgHeight.emit(this.heroImage.nativeElement.height);
      }, 500);
    }
  }

}
