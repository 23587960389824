import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '../../app.interface';
import * as fromGenerateQuote from './generate-quote';
import * as fromQuote from './quote';
import { Action } from '@ngrx/store';


export * as fromQuote from './quote';


declare module '@ngrx/store' {
  export interface UnsafeAction extends Action {
    payload?: any;
  }
}


export interface QuoteState {
  generateQuote: fromGenerateQuote.State;
  quote: fromQuote.State;
}

export interface State extends AppState {
  generateQuote: QuoteState;
  quote: QuoteState;
}

export const reducers = {
  generateQuote: fromGenerateQuote.reducer,
  quote: fromQuote.reducer
};

export const getGenerateQuoteState = createFeatureSelector<QuoteState>('generateQuote');

export const getQuoteState = createFeatureSelector<QuoteState>('quote');

export const getGenerateQuoteEntityState = createSelector(
  getGenerateQuoteState,
  (state) => state.generateQuote
);

export const getQuoteEntityState = createSelector(
  getQuoteState,
  (state) => state.quote
);
