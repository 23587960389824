import { ConfirmationDetails, BillingInformation } from '.';

export interface OrderInformation{
    isPriceChanged: boolean;
    isPurchaseConfirmed: boolean;
    isCanceled: boolean;
    price: number;
    validationMessages: string[];
    paymentMethod: number;
    isCashWithHeldEnabled: boolean;
    isCashWithHeldVisible: boolean;
    isConfirmationEmailedToAgent: boolean;
    agentEmail: string;
    confirmationDetails: ConfirmationDetails;
    billingInformation: BillingInformation;
}