import { InputField, ErrorMessage } from '../../shared/models';

export interface BillingInformation{
    isPayMethodCashWithheld: InputField<boolean>;
    receiptNumber: InputField<string>;
    cardholderFullName: InputField<string>;
    cardholderAddress1: InputField<string>;
    cardholderAddress2: InputField<string>;
    cardholderCity: InputField<string>;
    cardholderState: InputField<string>;
    cardholderZipCode: InputField<string>;
    cardholderCountryCode: InputField<string>;
    fulfillmentPrimaryEmail: InputField<string>;
    fulfillmentSecondaryEmail: InputField<string>;
    cardholderPhoneNumber: InputField<string>;
    cardNumber: InputField<string>;
    cardExpirationMonth: InputField<number | null>;
    cardExpirationYear: InputField<number | null>;
    isFulfillmentByMail: InputField<boolean>;
    cardTypeCodeString: InputField<string>;
    validationMessages: ErrorMessage[];
}