import { Component, OnInit, ViewEncapsulation, Inject, Directive  } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { AlertTypeEnum } from '../../../alert/alert-type.enum';
import { SanitizeHtmlPipe } from '../../../pipes/sanitize-html.pipe';
import { MatButtonModule } from '@angular/material/button';


@Component({
    selector: 'app-content-dialog',
    templateUrl: './content-dialog.component.html',
    styleUrls: ['./content-dialog.component.scss'],
    encapsulation: ViewEncapsulation.None,
    imports: [MatDialogModule, MatButtonModule, SanitizeHtmlPipe]
})
export class ContentDialogComponent implements OnInit {

  alertTypeEnum = AlertTypeEnum;

  constructor(
    public dialogRef: MatDialogRef<ContentDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string, type: AlertTypeEnum }) { }

  ngOnInit() {
  }

}
