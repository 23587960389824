export interface Faq {
    id: number;
    displayName: string;
    internalName: string,
    questions: Question[];
}

export interface Question {
    question: string;
    answer: string;
}