import { GET_COVERAGE_OPTIONS_FAILED } from './../../shared/constants/alert-messages.constants';
import { AlertService } from './../../shared/alert/services/alert.service';
import { ContentBlock } from './../../shared/models/content-block.interface';
import { ContentManagementService } from './../../shared/services/content-management.service';
import { AppStateService } from './../../shared/services/app-state.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, Directive } from '@angular/core';
import { Store } from '@ngrx/store';
import { Alert } from '../../shared/actions/alert';
import { AlertState } from '../../shared/reducers';
import { AlertTypeEnum } from '../../shared/alert/alert-type.enum';
import { ContentBlockContainerComponent } from '../../shared/components/content-block-container/content-block-container.component';
import { NgIf } from '@angular/common';
import { HeroImageComponent } from '../../shared/components/hero-image/hero-image.component';


@Component({
    selector: 'app-product-information',
    templateUrl: './product-information.component.html',
    styleUrls: ['./product-information.component.scss'],
    imports: [HeroImageComponent, NgIf, ContentBlockContainerComponent]
})
export class ProductInformationComponent implements OnInit {

  public pageTitle = `Product Information`;
  contentBlocks: Array<ContentBlock>;
  MAXBLOCKS = -1;

  public heroImageUrl: string;
  public introContent: string;

  constructor(
    private route: ActivatedRoute,
    private appStateService: AppStateService,
    private contentManagementService: ContentManagementService,
    private store: Store<AlertState>
  ) { }

  ngOnInit() {
    this.contentManagementService.tryGetAgencyConfig().subscribe(site => {
      this.heroImageUrl = site.productHeroImage;
      this.introContent = site.productIntroContent;
      this.contentManagementService.getProductInformation(site.productContents).subscribe(blocks => {
        this.contentBlocks = blocks;
      }, err => {
        this.store.dispatch(
          new Alert({
            message: GET_COVERAGE_OPTIONS_FAILED,
            alertType: AlertTypeEnum.ERROR
          })
        );
      });
    });
  }

}
