import { ProductBenefitInfo, ProductParagraphInfo } from './index';

export interface ProductInformation {
    productId: number;
    psProductId: string;
    productName: string;
    isSwitchToAddOnProduct: boolean;
    isRequiredAddOnProduct: boolean;
    productEffectiveDate: string;
    formattedProductEffectiveDate: string;
    productExpirationDate: string;
    formattedProductExpirationDate: string;
    productNotes: string;
    customerProductNotes: string;
    productBenefitList: ProductBenefitInfo[];
    productParagraphList: ProductParagraphInfo[];
    agentTips: string[];
    isRecommended: boolean;
    isAnnualProduct: boolean;
    recommendationReason: string;
    predecessorPSId: string;
}
