<form [formGroup]="eventRegistrationForm" (ngSubmit)="onSubmit()" class="form" novalidate>


  <div class="form-row top-row-wrapper">
    <div class="form-group col-md-4">
      <label for="state">State of Residence</label>
      <select id="state" class="form-control form-control-sm" formControlName="state">
        <option value="" selected disabled>Choose a state</option>
        <option *ngFor="let state of states" value="{{state.code}}">{{state.code}}-{{state.desc}}</option>
      </select>
      <app-input-validation [inputFormControl]="state"></app-input-validation>
    </div>

    <div class="col-md-5">
      <div class="form-group">
        <label for="totalTripCost" placement="top" ngbTooltip="{{registrationCostTooltipMessage}}">Total Cost for Event/Activity 
          <span class="fa fa-question-circle"></span>
        </label>
        <div class="input-group input-group-sm">
            <div class="input-group-prepend">
              <span class="input-group-text" id="basic-addon1">$</span>
            </div>
          <input type="text" id="cost" class="form-control form-control-sm" formControlName="cost">
        </div>
        <app-input-validation [inputFormControl]="cost">
          <div class="validation-error__message" *ngIf="cost.errors?.pattern">Total Cost for Event/Activity should be between 0 and 1000000</div>
        </app-input-validation>
      </div>
    </div>
  </div>

  <div class="form-row departure-return-date">
    <div class="col-md-4">
      <div class="form-group">
        <label for="numberOfTickets"># of Passes/Activities</label>
        <select id="numberOfTickets" class="form-control form-control-sm" formControlName="numberOfTickets">
          <option value="" selected disabled>Select Amount</option>
          <option value=1>1</option>
          <option value=2>2</option>
          <option value=3>3</option>
          <option value=4>4</option>
          <option value=5>5</option>
          <option value=6>6</option>
          <option value=7>7</option>
          <option value=8>8</option>
          <option value=9>9</option>
          <option value=10>10</option>
        </select>
        <app-input-validation [inputFormControl]="numberOfTickets"></app-input-validation>
      </div>
      </div>
      <div class="col-sm-12 col-md-5">
        <div class="form-group">
          <label for="beginEndDate">Event/Activity Begin - End Date </label>
          <p-calendar #rangeDatePicker class="custom" data-test-id="fromToDate"  formControlName="beginEndDate" selectionMode="range" [readonlyInput]="true"
          [minDate]="minDateTomorrow" [numberOfMonths]="displayMonths" [showIcon]="true" icon="pi pi-calendar" (onClose)="onClose()" (onSelect)="onSelect()">
            <ng-template pTemplate="date" let-date>
              <span id= "{{date.month}}{{date.day}}" >{{date.day}}</span>
            </ng-template> 
            <p-footer>
              <button type="button" mat-button class="link" (click)="onClose()">Done</button>
            </p-footer>
          </p-calendar>
          <app-input-validation [inputFormControl]="beginEndDate">
            <div class="validation-error__message" *ngIf="beginEndDate.invalid">This field is required.</div>
          </app-input-validation>
        </div>
      </div>
    </div>

  <app-form-validation [errorMessages]="errors"></app-form-validation>

  <div class="form-row">
    <div class="col">
      <app-dynamic-button [compId]="componentId + 'event-reg-btn'" type="submit" [disabled]="buttonDisabled" [buttonText]="quoteButtonText" class="pull-right"  [buttonBackgroundColor]="quoteButtonColor" [buttonHoverColor]="quoteButtonHoverColor"></app-dynamic-button>
    </div>
  </div>
</form>