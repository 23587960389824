import { Component, OnInit, Input, Directive  } from '@angular/core';


@Component({
    selector: 'app-route-header',
    templateUrl: './route-header.component.html',
    styleUrls: ['./route-header.component.scss'],
    standalone: true
})
export class RouteHeaderComponent implements OnInit {

  @Input() title: string;

  constructor() { }

  ngOnInit() {
  }

}
